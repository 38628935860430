import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Error from "./Error";
import { Row, Col } from "reactstrap";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Please enter at least two characters")
    .max(255, "Plase use less than 255 characters")
    .required("Must enter a name"),
  email: Yup.string()
    .email("Plase use a valid email address")
    .max(255, "Plase use less than 255 characters")
    .required("Must enter an email"),
  phone: Yup.string()
    .min(10, "Please enter at least 10 characters")
    .max(15, "Plase use less than 16 characters")
    .required("Must enter a phone number"),
  company: Yup.string()
    .min(2, "Please enter at least two characters")
    .max(255, "Plase use less than 255 characters")
    .required("Must enter a company name"),
  message: Yup.string()
    .min(3, "Please enter at least three characters")
    .max(255, "Plase use less than 255 characters")
    .required("Must enter a message")
});
export default function FormikForm() {
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        phone: "",
        company: "",
        message: ""
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const { name, email } = this.state;
        const isEnabled = name.length > 0 && email.length > 0;
        setSubmitting(false);

        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          resetForm();
          setSubmitting(false);
        }, 500);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid
      }) => (
        <form
          className="contact-form"
          data-netlify="true"
          action="https://formspree.io/myroqnqx"
          method="post"
        >
          <Row>
            <Col sm={{ size: 7 }}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                className={touched.name && errors.name ? "has-error" : null}
              />
              <Error touched={touched.name} message={errors.name} />
            </Col>
            <Col sm={{ size: 7 }}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className={touched.email && errors.email ? "has-error" : null}
              />
              <Error touched={touched.email} message={errors.email} />
            </Col>
            <Col sm={{ size: 7 }}>
              <label htmlFor="phone">Phone</label>
              <input
                type="phone"
                name="phone"
                id="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                className={touched.phone && errors.phone ? "has-error" : null}
              />
              <Error touched={touched.phone} message={errors.phone} />
            </Col>
            <Col sm={{ size: 7 }}>
              <label htmlFor="Company">company</label>
              <input
                type="text"
                name="company"
                id="company"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.company}
                className={
                  touched.company && errors.company ? "has-error" : null
                }
              />
              <Error touched={touched.company} message={errors.company} />
            </Col>
          </Row>

          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            id="message"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.message}
            className={touched.message && errors.message ? "has-error" : null}
          />
          <Error touched={touched.message} message={errors.message} />

          <button
            className="cta-button contact-form__btn"
            type="submit"
            disabled={!isValid}
          >
            {isValid ? "SUBMIT" : "All Fields Required"}
          </button>
        </form>
      )}
    </Formik>
  );
}
