import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FormikForm from "../components/forms/FormikForm";
import Img from "gatsby-image";
import { Container, Row, Col } from "reactstrap";
import SideArrow from "../components/icons/arrows/SideArrow";
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";

const Contact = ({ data }) => {
  // eslint-disable-next-line
  let parallax;
  return (
    <Parallax ref={ref => (parallax = ref)}>
      <Layout>
        <SEO
          title={data.wpgraphql.page.title}
          description={data.wpgraphql.page.acf_page_meta.metadescription}
        />
        <article className="contact">
          <header className="header--fullwidth header--darkbg">
            {data.wpgraphql.page.featuredImage != null && (
              <Img
                className="header__image"
                fluid={
                  data.wpgraphql.page.featuredImage.imageFile.childImageSharp
                    .fluid
                }
                alt={data.wpgraphql.page.featuredImage.altText}
              />
            )}
            <Container>
              <Row>
                <Col xs={{ offset: 1 }}>
                  <h1 className="heading1 header__message">
                    {/* {data.wpgraphql.page.acf_contact.heroHead} */}
                    <strong>Get Connected.</strong>
                    <br /> Become a Partner
                  </h1>
                </Col>
              </Row>
            </Container>
          </header>
          <Container>
            <Col lg={{ size: 7, offset: 7 }}>
              <p className="hero-text body-copy--large">
                {data.wpgraphql.page.acf_contact.heroText}
              </p>
            </Col>
          </Container>
          <ParallaxLayer
            offset={0}
            speed={0.15}
            className="d-none d-xl-block"
          >
            <SideArrow className="parallax__arrow-right" />
          </ParallaxLayer>
          <section className="section__contact-info">
            <Container>
              <Row>
                <Col md={{ size: 4, offset: 1 }}>
                  <h2 className="heading2">Contact Us.</h2>
                  <ul className="contact__list">
                    <li>
                      {data.wpgraphql.page.acf_contact.primaryContactName}
                    </li>
                    <li>
                      {data.wpgraphql.page.acf_contact.primaryContactTitle}
                    </li>
                    <li>
                      <a href="mailto:{data.wpgraphql.page.acf_contact.primaryContactEmail}">
                        {data.wpgraphql.page.acf_contact.primaryContactEmail}
                      </a>
                    </li>
                    <li>
                      <a href="tel:{data.wpgraphql.page.acf_contact.primaryContactPhone}">
                        {data.wpgraphql.page.acf_contact.primaryContactPhone}
                      </a>
                    </li>
                  </ul>
                  <p className="contact__address">
                    {data.wpgraphql.page.acf_contact.address1}
                    <br />
                    {data.wpgraphql.page.acf_contact.address2}
                  </p>
                </Col>
                <Col md={{ size: 7, offset: 1 }}>
                  <div>
                    <FormikForm />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </article>
      </Layout>
    </Parallax>
  );
};

export default Contact;

export const query = graphql`
  query {
    wpgraphql {
      page(id: "cGFnZTo4OQ==") {
        title
        acf_page_meta {
          metadescription
        }
        acf_contact {
          heroHead
          heroText
          primaryContactName
          primaryContactTitle
          primaryContactEmail
          primaryContactPhone
          address1
          address2
        }
        featuredImage {
          altText
          sourceUrl
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
